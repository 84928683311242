<template>
  <div>
    <FormulateForm class="text-left" v-model="profileBillingAddress">
      <div class="flex flex-wrap">
        <FormulateInput
          v-mask="zipcodeMaskFormat"
          type="text"
          :label="$t('form.postal_code')"
          name="zipcode"
          icon="id-card"
          inputmode="text"
          validation="required"
          :validation-messages="{
            required: $t('form.required_field'),
          }"
          :error-class="['text-red-600']"
          :outer-class="defaultPostalCodeClass"
          data-cy="profile_form-postal-code"
          @keyup="update"
          @click="eventGtm('custom.editPostalCode')"
        />

        <FormulateInput
          type="text"
          name="street"
          :label="$t('form.address')"
          icon="map-signs"
          validation="required"
          :validation-messages="{
            required: $t('form.required_field'),
          }"
          :outer-class="defaultAddressClass"
          :error-class="['text-red-600']"
          data-cy="profile_form-street-input"
          @keyup="update"
          @click="eventGtm('custom.editStreet')"
        />

        <FormulateInput
          type="text"
          name="number"
          :label="$t('form.number')"
          :validation="
            ['required'].filter(() => countriesRequireAddress.includes(country))
          "
          :validation-messages="{ required: $t('form.required_field') }"
          :outer-class="['w-full sm:w-3/4 sm:pl-1 md:w-1/6 md:pr-1']"
          :error-class="['text-red-600']"
          data-cy="profile_form-number-input"
          @keyup="update"
          v-if="this.requireAddress"
        />

        <FormulateInput
          type="text"
          name="city"
          :label="$t('form.city')"
          validation="required"
          :validation-messages="{
            required: $t('form.required_field'),
          }"
          :outer-class="[this.defaultClass]"
          :error-class="['text-red-600']"
          data-cy="profile_form-city-input"
          @keyup="update"
          @click="eventGtm('custom.editCity')"
          @blur="update"
          @change="update"
        />
        <div
          v-if="requireAddress"
          data-classification="select"
          data-has-value="true"
          data-type="select"
          class="formulate-input w-full sm:w-1/3 sm:pl-1 md:w-1/2"
        >
          <div v-if="requireAddress" class="formulate-input-wrapper">
            <label
              v-if="requireAddress"
              for="formulate--assinar-recorrencia-dados-para-nota-fiscal-22"
              class="formulate-input-label formulate-input-label--before"
            >
              {{ $t("form.state") }}
            </label>
            <div
              v-if="requireAddress"
              data-type="select"
              class="formulate-input-element formulate-input-element--select"
              @click="update"
            >
              <select
                v-if="requireAddress"
                data-cy="profile_form-state-input"
                id="formulate--assinar-recorrencia-dados-para-nota-fiscal-18"
                name="state"
                class="nex-input"
                v-model="profileBillingAddress.state"
                @click="update"
              >
                <option v-for="option in stateList" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IMaskDirective } from "vue-imask";
import { mask } from "vue-the-mask";
import { STORE_MODULE } from "@/store/constants/store";
import { statesObject } from "../../utils/states";

export default {
  name: "FormAddress",
  directives: {
    mask,
    imask: IMaskDirective,
  },
  props: {
    addressType: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      countriesRequireAddress: ["AR", "BO", "CO", "CL"],
      isArgentina: false,
      requireAddress: false,
      profileBillingAddress: {},
      loadingZipcode: false,
      zipcode: null,
      validZipcode: false,
      isCEPChecked: false,
      stateList: [],
      state: "",
      zipcodeMask: {
        mask: "00000-000",
      },
      errorCEP: false,
      defaultClass: "w-full sm:w-2/3 sm:pr-1 md:w-1/2 md:pl-1",
      defaultPostalCodeClass: ["w-full sm:w-3/4 sm:pl-1 md:w-2/6 md:pr-1"],
      defaultAddressClass: ["w-full sm:w-3/4 sm:pl-1 md:w-3/6 md:pr-1"],
    };
  },
  computed: {
    ...mapGetters(STORE_MODULE, ["billingAddress"]),
    ...mapGetters({ eventGtm: "eventGtm", country: "getCountry" }),
    zipcodeMaskFormat() {
      if (this.isArgentina) {
        return ["####"];
      }
      return ["XXXXXXXXXXXXXXXXXXXX"];
    },
  },
  created() {
    const country = this.billingAddress.country;
    this.requireAddress = this.countriesRequireAddress.includes(country);
    this.stateList = statesObject[country];

    if (!this.requireAddress) {
      this.defaultPostalCodeClass = [
        "w-full sm:w-3/4 sm:pl-1 md:w-1/2 md:pr-1",
      ];
      this.defaultAddressClass = ["w-full sm:w-3/4 sm:pl-1 md:w-1/2 md:pr-1"];
    }
  },
  async mounted() {
    this.profileBillingAddress = this.billingAddress;
    this.zipcode = this.profileBillingAddress.zipcode;
    this.isArgentina = this.billingAddress.country === "AR";
    this.profileBillingAddress.state = this.countriesRequireAddress.includes(
      this.billingAddress.country
    )
      ? this.stateList.filter((item) =>
          item.includes(this.profileBillingAddress.state)
        )[0]
      : this.profileBillingAddress.state;
    this.update();

    if (!this.countriesRequireAddress.includes(this.billingAddress.country)) {
      this.defaultClass = "w-full sm:pr-1 md:pl-1";
    }
  },
  methods: {
    saveCurrentData() {
      this.$emit("addressData", this.profileBillingAddress);
      this.$emit("setError", false);
    },
    emitError() {
      this.$emit("setError", true);
    },
    update() {
      this.saveCurrentData();
    },
  },
};
</script>
